<div class="row">
  <div class="col-md-12 nopad">
    <div class="card content ">

      <div class=" Head ">



        <div class="card-header  d-flex justify-content-between align-items-center">
          <h3>Bank Details</h3>

          <div class="dropdown">

            <a (click)="Back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
              <b>X</b>
            </a>


          </div>
        </div>

      </div>

      <div class="content_body">



        <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()" novalidate>



          <div class="row">

            <div class="col-sm-6">

              <div class="form-table table-responsive">
                <table class="table table-bordered">


                  <tbody>
                    <tr class="hidden">
                      <td>ID <span class="text-danger">*</span></td>
                      <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID" #ID="ngModel" required>
                      </td>
                    </tr>


                    <tr>
                      <td>Bank Name<span class="text-danger">*</span></td>
                      <td>
                        <input class="form-control ng-touched" type="text" [(ngModel)]="add.Bank_Name" required
                          name="Bank_Name" #Bank_Name="ngModel">
                        <div *ngIf="addForm.submitted && Bank_Name.invalid" class="invalid-feedback d-block">
                          <div class="error">Bank Name Should not Empty</div>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td>Account Number<span class="text-danger">*</span></td>
                      <td> <input class="form-control" type="text" [(ngModel)]="add.Account_Number" required
                          name="Account_Number" #Account_Number="ngModel">
                        <div *ngIf="addForm.submitted && Account_Number.invalid" class="invalid-feedback d-block">
                          <div class="error">Account Number Should not Empty</div>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td>Account Name<span class="text-danger">*</span></td>
                      <td><input class="form-control" type="text" [(ngModel)]="add.Account_Name" required
                          name="Account_Name" #Account_Name="ngModel">
                        <div *ngIf="addForm.submitted && Account_Name.invalid" class="invalid-feedback d-block">
                          <div class="error">Account Name Should not Empty</div>
                        </div>
                      </td>
                    </tr>



                    <tr>
                      <td>Branch<span class="text-danger">*</span></td>
                      <td> <input class="form-control" type="text" [(ngModel)]="add.Branch" name="Branch"
                          #Branch="ngModel"></td>
                    </tr>



                    <tr>
                      <td>IFSC Code<span class="text-danger">*</span></td>
                      <td><input class="form-control" type="text" [(ngModel)]="add.IFSC_Code" required name="IFSC_Code"
                          #IFSC_Code="ngModel">
                        <div *ngIf="addForm.submitted && IFSC_Code.invalid" class="invalid-feedback d-block">
                          <div class="error">IFSC Code Should not Empty</div>
                        </div>
                      </td>
                    </tr>

                  </tbody>

                </table>

                <div class="invoice-btn-box text-right " style="padding-bottom:20px">

                  <button class="btn btn-info rounded-pil" type="submit"> <i class='bx bx-paper-plane'></i> Save
                  </button> &nbsp;&nbsp;
                  <button class="btn btn-secondary rounded-pil " type="button" (click)="Back()">Cancel</button>
                  &nbsp;&nbsp;
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>