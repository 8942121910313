import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { CustomersComponent } from './master/customers/customers.component';
import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
const routes: Routes = [
  { path: 'add-customer', component: CustomersComponent },
  { path: 'customer-details', component: CustomerDetailsComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'sales-dashboard', component: SalesDashboardComponent },
  { path: '', component: LoginComponent },
  {
    path: 'authentication', component: AuthenticationComponent,
    children: [
      { path: 'login', pathMatch: 'full', data: { breadcrumb: 'Login' }, component: LoginComponent },
      { path: 'register', data: { breadcrumb: 'Register' }, component: RegisterComponent },
      { path: 'forgot-password', data: { breadcrumb: 'Forgot Password' }, component: ForgotPasswordComponent },
      { path: 'reset-password', data: { breadcrumb: 'Reset Password' }, component: ResetPasswordComponent },
    ]
  },

  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'master', loadChildren: () => import('./master/master.module').then(m => m.MasterModule) },
  { path: 'utilities', loadChildren: () => import('./utilities/utilities.module').then(m => m.UtilitiesModule) },
  { path: 'visitor', loadChildren: () => import('./visitor/visitor.module').then(m => m.VisitorModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
