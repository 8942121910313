<!-- Top Navbar Area -->
<nav class="navbar top-navbar navbar-expand is-sticky" id="navbar">
    <div class="collapse navbar-collapse" id="navbarSupportContent">
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
        <span class="head  hb_title">&nbsp;&nbsp; </span>
        <ul class="navbar-nav left-nav align-items-center" style="    margin-top: -10px;
        margin-left: 10px;">



            <li style="FONT-FAMILY: system-ui;
FONT-WEIGHT: 700;
color: #000000b5;
padding-top: 4px;">VISITOR MANAGEMENT SYSTEM</li>



        </ul>

        <form class="nav-search-form d-none ml-auto d-md-block">
            <div style="line-height: 45px;">&nbsp;</div>
        </form>

        <ul class="navbar-nav right-nav align-items-center" style="right: 0px;
        position: absolute;">
            <li></li>



            <li class="nav-item notification-box dropdown hidden">
                <a routerLink="notification" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <div class="notification-btn">
                        <i class='bx bx-bell'></i>

                        <span class="badge badge-secondary">{{appservice.lenth}}</span>
                    </div>
                </a>


            </li>

            <li class="nav-item dropdown profile-nav-item">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <div class="menu-profile">
                        <span style="font-size: 17px;" class="name">Hi! {{data.UM_Full_Name}}</span>
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                    </div>
                </a>
                <div class="dropdown-menu">
                    <div class="dropdown-header d-flex flex-column align-items-center">
                        <div class="figure mb-3">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        </div>

                        <div class="info text-center">
                            <span class="name">{{data.UM_Full_Name}}</span>
                            <p style="margin-bottom: 0;">{{data.UM_User_Name}}</p>
                            <p style="margin-bottom: 0;">{{appservice.Emp_Dept}}</p>
                            <p style="margin-bottom: 0;">{{appservice.Emp_Approver_Name}}</p>


                        </div>
                    </div>

                    <div class="dropdown-body">
                        <ul class="profile-nav p-0 pt-3">






                            <li class="nav-item">
                                <a class="nav-link" routerLink="/change-password">
                                    <i class='bx bx-cog'></i> <span style="color: #000;">Change Password</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a (click)="logout()" class="nav-link">
                                    <i class='bx bx-log-out'></i> <span style="color: #000;">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>


                </div>
            </li>
        </ul>
    </div>


</nav>




<!-- End Top Navbar Area -->